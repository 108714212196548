import axios from 'axios';

export default axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    validateStatus: function (status) {
        return status == 200 || status == 201;
    }
});