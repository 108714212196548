import Service from '../../../services/Service';

class GantService extends Service {

    basePath = 'gant';

    newObject() {
        return new GantService();
    }
    
}

export default GantService;