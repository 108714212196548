<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb :breadcrumbs="[{ label: 'Диаграмма Ганта' }]" />
      <h4 class="page-title">Диаграмма Ганта</h4>
    </div>
  </div>
  <div class="d-block d-md-none">
    <div class="text-center gant_button_month mb-2">
      {{ monthName }} {{ year }}
    </div>
    <div class="d-flex">
      <button
        :disabled="this.loading"
        class="gant_button_month mb-2 me-1"
        @click="this.changeMonth('prev')"
      >
        Предыдущий месяц
      </button>
      <button
        :disabled="this.loading"
        class="gant_button_month mb-2 ms-1"
        @click="this.changeMonth('next')"
      >
        Следующий месяц
      </button>
    </div>
  </div>
  <div class="d-none d-md-flex justify-content-between mb-3">
    <button
      :disabled="this.loading"
      class="gant_button_month"
      @click="this.changeMonth('prev')"
    >
      Предыдущий месяц
    </button>
    <div class="gant_button_month">{{ monthName }} {{ year }}</div>
    <button
      :disabled="this.loading"
      class="gant_button_month"
      @click="this.changeMonth('next')"
    >
      Следующий месяц
    </button>
  </div>
  <div style="background-color: #fff; overflow-x: auto">
    <table v-if="gantList.data && gantList.data.data.countDays">
      <th class="gant_th" style="width: 17%; max-width: 350px"></th>
      <th
        class="gant_th text-center"
        style="
          width: 30px;
          min-width: 30px;
          border-right: solid 1px #000;
          border-left: solid 1px #000;
        "
        v-for="item in gantList.data.data.countDays"
        :key="item"
      >
        {{ item }}
      </th>
      <th class="gant_th text-center" style="width: 6%">Всего</th>
      <tr
        style="height: 60px; margin-top: 10px; border-bottom: solid 1px #000"
        v-for="user in gantList.data.data.users"
        :key="user.id"
      >
        <td class="gant_column_user">
          {{ user.surname }} {{ user.name }} {{ user.second_name }}
        </td>
        <td
          class="text-center p-0"
          style="border-left: 1px solid #000"
          v-for="item in gantList.data.data.countDays"
          :key="item"
        >
          <div v-if="user.userTrips.length >= 1">
            <div
              @click="this.goToTrip(item, user)"
              v-if="this.userHasTrip(item, user) === 'trip'"
              class="gant_trip_true d-block"
            >
              _
            </div>
            <div
              @click="this.goToTrip(item, user)"
              v-if="this.userHasTrip(item, user) === 'oneDay'"
              class="gant_trip_oneDay d-block"
            >
              _
            </div>
            <div
              @click="this.goToTrip(item, user)"
              v-if="this.userHasTrip(item, user) === 'first'"
              class="gant_trip_first d-block"
            >
              _
            </div>
            <div
              @click="this.goToTrip(item, user)"
              v-if="this.userHasTrip(item, user) === 'last'"
              class="gant_trip_last d-block"
            >
              _
            </div>
          </div>
        </td>
        <td class="text-center gant_column_sum">
          {{ user.countDaysInTrip }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import http from "@/utils/http-common";
import BreadCrumb from "@/components/ui/BreadCrumb";
import GantService from "@/modules/gant/services/GantService";
import Str from "@/utils/Str";

export default {
  components: {
    BreadCrumb,
  },
  data() {
    return {
      Str: Str,
      gantList: {},
      month: null,
      year: null,
      loading: null,
      service: new GantService(),
      curTripId: 562,
    };
  },
  async created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      this.gantList = await http
        .get(`gant`, {
          params: {
            month: this.month,
            year: this.year,
          },
        })
        .then(async (response) => {
          this.year = response.data.data.year;
          this.month = response.data.data.month;
          this.loading = false;
          return response;
        })
        .catch((e) => console.log(e));
    },
    goToTrip(day, userData) {
      userData.userTrips.forEach((element) => {
        if (element.firstDay < day && element.lastDay > day) {
          this.$router.push({
            name: "TripShow",
            params: { id: element.tripId },
          });
        } else if (element.firstDay == day && element.lastDay == day) {
          this.$router.push({
            name: "TripShow",
            params: { id: element.tripId },
          });
        } else if (element.firstDay == day) {
          this.$router.push({
            name: "TripShow",
            params: { id: element.tripId },
          });
        } else if (element.lastDay == day) {
          this.$router.push({
            name: "TripShow",
            params: { id: element.tripId },
          });
        }
      });
    },
    changeMonth(way) {
      if (way == "next") {
        this.month++;
      } else if (way == "prev") {
        this.month--;
      }
      if (this.month <= 0) {
        this.month = 12;
        this.year--;
      } else if (this.month >= 13) {
        this.month = 1;
        this.year++;
      }
      this.getData();
    },
    userHasTrip(day, userData) {
      var result = null;
      userData.userTrips.forEach((element) => {
        if (element.firstDay < day && element.lastDay > day) {
          this.curTripId = element.tripId;
          result = "trip";
        } else if (element.firstDay == day && element.lastDay == day) {
          this.curTripId = element.tripId;
          result = "oneDay";
        } else if (element.firstDay == day) {
          this.curTripId = element.tripId;
          result = "first";
        } else if (element.lastDay == day) {
          this.curTripId = element.tripId;
          result = "last";
        }
      });
      return result;
    },
  },
  computed: {
    monthName: function () {
      switch (this.month) {
        case 1: {
          return "Январь";
        }
        case 2: {
          return "Февраль";
        }
        case 3: {
          return "Март";
        }
        case 4: {
          return "Апрель";
        }
        case 5: {
          return "Май";
        }
        case 6: {
          return "Июнь";
        }
        case 7: {
          return "Июль";
        }
        case 8: {
          return "Август";
        }
        case 9: {
          return "Сентябрь";
        }
        case 10: {
          return "Октябрь";
        }
        case 11: {
          return "Ноябрь";
        }
        case 12: {
          return "Декабрь";
        }
      }
    },
  },
};
</script>
